#StoreBranch {
    width: 100vw;
    display:  grid;
    padding: 14px;
    grid-template-columns: repeat(auto-fit, minmax(478px, 1fr));
    gap: 14px;
}

.storeBranchCard {
    display: grid;
    grid-template-columns: 148px auto;
    padding: 9px;
}

.storeBranchCard > img {
    border-radius: 14px;
    grid-row: 1 / span 4;
}
.storeBranchCard > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.branchCardAddress {
    display: grid;
    grid-template-columns: 44px auto;
    padding: 8px 0;
    text-overflow: ellipsis;
    max-lines: 1;
}

#storeMedia {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    grid-template-columns: auto auto;
    gap: 14px;
    justify-content: space-between;
}

#storeMedia > img {
    border-radius: 14px;
    width: 700px;
    object-fit: cover;
}

#chainTitle {
    text-align: center;
    background: radial-gradient(
    /* to right, */
    #fe7a7a,
    #d489fc,
    #87c3fb,
    /* #f679b8, */
    #f37783,
    #f9849f
  );
	background-size: 700% 700%;
	animation: gradient 15s ease infinite;

    font-size: 72px;
    font-weight: 900;
    /* background: -webkit-linear-gradient(#eee, #333); */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 7px 22px;

    
}