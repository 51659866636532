#webView {
    padding: 14px;
    margin: auto;
    
    max-width: 1242px;

    display: grid;
    grid-template-columns: 50% 20% 30% ;
    grid-template-rows:
        242px
        42px
        auto
        auto;

    gap: 14px;

    justify-content: center;
    align-content: center;
}

#mobileView {
    width: 100%;
    margin: 0;
    padding: 7px;
    
    display: grid;
    grid-template-columns: auto;
    grid-template-rows:
        242px
        auto
        auto
        auto
        auto
        auto;

    gap: 7px;

    justify-content: stretch;
    align-content: center;
}

#invalidStoreMessage {
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;

    
}

#invalidStoreMessage > image {
    width: 100vw;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 200;
    top: 0;
    right: 0;
}

.canvasButton {
    width: 100%;
    height: 42px;
    border-radius: 14px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.canvasButton > h4 {
    color: white;
}


.loaderSmall {
    margin: 30px auto;
    width: 22px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #F10C49;
    animation: l10 1.5s infinite linear;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* HTML: <div class="loader"></div> */
.loader {
    margin: 300px auto;
    width: 22px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #F10C49;
    animation: l10 1.5s infinite linear;
}
@keyframes l10 {
    0%    {box-shadow: 
            0 -30px #F4DD51, calc(30px*0.707) calc(-30px*0.707) #E3AAD6,30px 0 #F4DD51, 0 0 #E3AAD6,
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6}
    12.5% {box-shadow: 
            0 0 #F4DD51, calc(30px*0.707) calc(-30px*0.707) #E3AAD6,30px 0 #F4DD51, calc(30px*0.707) calc(30px*0.707) #E3AAD6,
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6}
    25%   {box-shadow: 
            0 0 #F4DD51, 0 0 #E3AAD6,30px 0 #F4DD51, calc(30px*0.707) calc(30px*0.707) #E3AAD6,
            0 30px #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6}
    37.5% {box-shadow: 
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, calc(30px*0.707) calc(30px*0.707) #E3AAD6,
            0 30px #F4DD51, calc(-30px*0.707) calc(30px*0.707) #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6}
    50%   {box-shadow: 
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6,
            0 30px #F4DD51, calc(-30px*0.707) calc(30px*0.707) #E3AAD6,-30px 0 #F4DD51, 0 0 #E3AAD6}
    62.5% {box-shadow: 
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6,
            0 0 #F4DD51, calc(-30px*0.707) calc(30px*0.707) #E3AAD6,-30px 0 #F4DD51, calc(-30px*0.707) calc(-30px*0.707) #E3AAD6}
    75%   {box-shadow: 
            0 -30px #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6,
            0 0 #F4DD51, 0 0 #E3AAD6,-30px 0 #F4DD51, calc(-30px*0.707) calc(-30px*0.707) #E3AAD6}
    87.5% {box-shadow: 
            0 -30px #F4DD51, calc(30px*0.707) calc(-30px*0.707) #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6,
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, calc(-30px*0.707) calc(-30px*0.707) #E3AAD6}
    100%  {box-shadow: 
            0 -30px #F4DD51, calc(30px*0.707) calc(-30px*0.707) #E3AAD6,30px 0 #F4DD51, 0 0 #E3AAD6,
            0 0 #F4DD51, 0 0 #E3AAD6,0 0 #F4DD51, 0 0 #E3AAD6}
  
}

.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  padding: 0;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #f5f5f5;
  transform: scale(1.05);
}