
.categoriesGridView {
    display: grid;
    grid-column: 1 / span 2;
    grid-template-columns: repeat(auto-fit, minmax(242px, 1fr));
    gap: 14px;

    align-content: start;
}

#mobileView  .categoriesGridView {
    grid-column: 1 / span 1;
    grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
}

#webView #searchResultBox {
    grid-column: 1 / span 2;
}


.categorySelected {
    grid-column: 1/ -1;
}

.categorySelected > .category {
    display: grid;
    grid-template-columns: 27% 1fr 32px;
    grid-template-rows: 112px;
    aspect-ratio: unset;

    text-align: left;

    gap: 14px;
}

.categorySelected > .category > h3 {
    font-size: 32px;
    font-weight: 800;
    color: grey;
}

.category {
    display: grid;
    grid-template-rows: 142px 42px;
    justify-content: stretch;
    align-items: center;

    text-align: center;

    padding: 7px;
    
    border-radius: 14px;
    object-fit: cover;

    overflow: hidden;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;

    z-index: 100;
}

.category > h1 {
    font-size: 72px;
    font-weight: 400;
    color: grey;
    text-align: center;
    margin: auto
    ;
    transition: transform .2s; /* Animation */
}

.category > img:hover, h1:hover {
    transform: scale(72);
}

.category > h3 {
    margin: 0px;

    font-weight: 500;
}

.category > img {
    width: 100%;
    height: 100%;
    aspect-ratio: 2;

    object-fit: cover;
    border-radius: 14px;

    transition: transform .2s; /* Animation */
} 
.category img:hover, h1:hover {
    transform: scale(1.04);
}

.noItemsMessage {
    height: 472px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
