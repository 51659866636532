#webView #Cart {
    grid-column-start: 3;
    grid-row: 2 /span 2;

    position: sticky;
    top: 0;
}

#mobile #Cart {
    position: relative;
}

#Empty-Cart {
    padding: 27px;
    height: 172px;

    border-top-left-radius: 0;
    
    text-align:center;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#webView #Receipt {
    margin-bottom: 14px;
    position: fixed;

    width: 24.7%;
    bottom: 0;
}

#mobileView #Receipt {
    position: relative;
}


.CartServiceTile {
    display: grid;
    grid-template-columns: auto 97px 32px;
    gap: 7px;

    justify-content: stretch;
    align-items: center;
    padding: 14px 0;
    border-top: 0.2px solid lightgray;
}

.CartServiceTileForProduct {
    grid-template-columns: auto 82px 57px;
}


.CartDetailTile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
} 

#addMoreServicesButton > a {
    color: purple;
}
#addMoreServicesButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
}

#CartButtonBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
    padding: 0 14px 12px 14px;
    margin: 7px;
    width: 97vw;

    position: fixed;
    bottom: 0;
    z-index: 172;
}

#CartButtonBottom > a {
    width: 142px;
}


.CartInventoryButton {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: right;
}