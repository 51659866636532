.IconWrapper {
    border-radius: 7px;
    border: 1px solid grey;
    padding: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
}


#webView #DetailFooter {
    grid-column: 1 / span 2;
    padding: 14px;
}

#mobileView #DetailFooter {
    grid-row-start: 6;
    padding: 14px;
    margin-bottom: 82px;
}

#DetailFooter img {
    border-radius: 7px;
    padding: 4px;
    width: 42px;
    height: 42px;
}

#DetailFooter h4 {
    flex: 2;
    margin: 0;
    padding: 4px;
    grid-column-end: 3;
}
