.side-drawer { 
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    width: 342px;
    z-index: 300;
    /* overflow: scroll; */
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    padding: 32px;
    overflow-y: scroll;
 }
 .side-drawer.open {
    transform: translateX(0);
 }

.side-drawer h3 {
    margin: 0;
}


.genderRadio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.genderRadio > p {
    padding: 0;
    color: #999;
    font-size: 18px;
    font-weight: normal;
}

input[type=checkbox] {
    accent-color: rgb(170, 2, 170);
    width: 42px;
    cursor: pointer;
    height: 22px;
    width: 22px;
}

